<template>
	<v-app>
		<full-screen-view />
	</v-app>
</template>

<script>
export default {
	name: "FullscreenLayout",

	components: {
		FullScreenView: () =>
			import(
				/* webpackChunkName: "admin-default-view" */
				"./View"
			)
	}
};
</script>
